import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { UserSupport } from "@aptedge/lib-ui/src/types/entities";
import LZString from "lz-string";
import { Query, QueryClient } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { IQueryFilter, ITimeDimensionFilter } from "../components/Filter/types";
import { logger } from "./logger";

export interface IQueryArgs {
  timeDimensions?: ITimeDimensionFilter[];
  filters?: IQueryFilter[];
}

/**
 * Converts any object to a compressed and encoded string.
 *
 */
function toLZCompressedString<T>(obj: T): string {
  try {
    const stringified = JSON.stringify(obj);
    return LZString.compressToEncodedURIComponent(stringified);
  } catch (e) {
    logger.error(`Error compressing to URL string`, obj, e);
    return "";
  }
}

/**
 * Converts a compressed/encoded string to an object.
 *
 * Note: We have no way of guaranteeing that the query string has all
 * properties of the presumed type. We, therefore, return a partial
 * where every expected property might be undefined.
 */
function fromLZCompressedString<T>(str: string): Partial<T> {
  try {
    const obj = LZString.decompressFromEncodedURIComponent(str) ?? "{}";
    const parsed = JSON.parse(obj);

    return parsed;
  } catch (e) {
    logger.error(`Error decompressiong URL to object`, str, e);
    return {};
  }
}

function removeEmptyFilters<Q extends IQueryArgs>(args: Q): Q {
  const { filters: originalFilters, ...rest } = args;

  const filters = originalFilters?.filter((f) => f.values.length > 0);

  return { ...rest, filters } as Q;
}

const TWENTY_FOUR_HOURS_MILLIS = 1000 * 60 * 60 * 24;
const TWELVE_HOURS_MILLIS = 1000 * 60 * 60 * 12;
const TEN_MINUTES_MILLIS = 1000 * 60 * 10;

// Update every 10 minutes in the background, but values in the cache less than an hour old are
// valid. (E.g. if the user closes the app and opens it 55 minutes later, the previous value will
// be used while a new value is fetched in the background. But if they close the app and open it
// 65 minutes later, the UI will block while waiting for a new value to be fetched.)
const FEATURE_FLAGS_QUERY_OPTIONS = {
  refetchInterval: TEN_MINUTES_MILLIS,
  refetchOnMount: false,
  useErrorBoundary: true
};

// Cache for 24 hours, but update after 12 hours if the app is still being used. This is similar
// to the logic above, but without automatic retrievals. (Though perhaps the difference is minimal
// in practice.)
//
// N.B. that /api/whoami/ values are not cached when `onboardingComplete` is false -- see
// setupQueryClientPersistence().
const WHOAMI_QUERY_OPTIONS = {
  cacheTime: TWENTY_FOUR_HOURS_MILLIS,
  staleTime: TWELVE_HOURS_MILLIS,
  useErrorBoundary: true
};

function createQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });
}

// Make sure to render the app *after* the returned Promise completes, or queries may not use the
// cache on initial load!
function setupQueryClientPersistence(queryClient: QueryClient): Promise<void> {
  const persistor = createWebStoragePersistor({
    storage: window.localStorage,
    // This setting limits the rate at which values are added to the cache, which for some reason
    // they thought was important. By default, it's 1,000ms. This caused issues in our use case and
    // we don't write that often anyway, so we just set it to 1ms. (Zero may be possible, but the
    // source is confusing and I haven't tested it.)
    throttleTime: 1
  });

  return persistQueryClient({
    queryClient,
    persistor,
    dehydrateOptions: {
      shouldDehydrateQuery: function (query: Query): boolean {
        if (query.state.status !== "success") {
          // this is the default logic
          return false;
        } else if (!Array.isArray(query.queryKey) || !query.queryKey.length) {
          return false;
        } else if (
          // For now, we whitelist queries that can be cached in local storage. These are
          // particularly the ones on the critical path
          query.queryKey[0] === CACHE_KEY.SEARCH_FILTERS
        ) {
          return true;
        } else if (query.queryKey[0] === CACHE_KEY.WHOAMI) {
          // Bit of a special case: if onboarding is not complete, we want to detect immediately
          // when it has *changed* to complete. So we don't cache the result of /api/whoami/ in
          // local storage when `onboardingComplete` is false.
          const user = query.state.data as UserSupport;
          return user.onboardingComplete;
        }
        return false;
      }
    }
  });
}

export {
  toLZCompressedString,
  fromLZCompressedString,
  removeEmptyFilters,
  createQueryClient,
  setupQueryClientPersistence,
  TWENTY_FOUR_HOURS_MILLIS,
  TEN_MINUTES_MILLIS,
  FEATURE_FLAGS_QUERY_OPTIONS,
  WHOAMI_QUERY_OPTIONS
};
