import { GeneratedKnowledgeResult } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

interface FetchArticlesArgs {
  published: boolean;
  ticketId?: string;
}

function fetchArticles({
  published,
  ticketId
}: FetchArticlesArgs): Promise<GeneratedKnowledgeResult[]> {
  const url = ticketId
    ? `${getBackendApiUrl()}/generated_knowledge/?ticket_id=${ticketId}&published=${published}`
    : `${getBackendApiUrl()}/generated_knowledge/?published=${published}`;
  return request(url);
}

export { fetchArticles };
