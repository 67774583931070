import { IUserListing } from "@aptedge/lib-ui/src/types/entities";
import { useMemo } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { fetchUsers } from "../clients/User/fetchUsers";

function useUsers(): UseQueryResult<IUserListing[], Error> {
  return useQuery(WebCacheKey.USERS, fetchUsers, {
    refetchOnMount: false /** cache across components */
  });
}

function useUsersById(): UseQueryResult<Map<number, IUserListing>, Error> {
  const query = useUsers();
  const usersById = useMemo(
    () =>
      query.data
        ? new Map(query.data.map((user) => [user.id, user]))
        : undefined,
    [query.data]
  );
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(query as any),
    data: usersById
  };
}

export { useUsers, useUsersById };
