import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import IOSSwitch from "@aptedge/lib-ui/src/components/IOSSwitch/IOSSwitch";
import classNames from "classnames";
import { FC } from "react";
import SectionLayout from "../../../common/SectionLayout/SectionLayout";
import ConfigInput from "../../ConfigInput/ConfigInput";
import ConfigInputField from "../../ConfigInput/ConfigInputField/ConfigInputField";
import {
  ConfigProps,
  SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED,
  SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS,
  SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR,
  SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED,
  SELF_SERVICE_TICKET_CREATION_LINK_TEXT,
  SELF_SERVICE_TICKET_CREATION_URL,
  SELF_SERVICE_TITLE
} from "../../utils/configFieldKeys";
import styles from "./styles.module.scss";

const WarningText: FC<{ text: string }> = ({ text }) => {
  return (
    <div className={styles.warningText}>
      <AeIcons.InfoIcon className={styles.warningIcon} />
      <span>{text}</span>
    </div>
  );
};

export const GeneralSettings: FC<ConfigProps> = ({
  config,
  handleConfigChange,
  isLoading,
  handleAddNewPageGlob,
  removePageGlob
}) => {
  return (
    <div>
      <SectionLayout title="General Settings">
        <ConfigInput
          title="Welcome message"
          description="Enter a short title for the home page of the Self Service app"
        >
          <ConfigInputField
            name={SELF_SERVICE_TITLE}
            placeholder="Enter a welcome text"
            value={config?.[SELF_SERVICE_TITLE] ?? ""}
            onChange={handleConfigChange}
            attrs={{ disabled: isLoading }}
          />
        </ConfigInput>

        <ConfigInput
          title="Additional support"
          className={styles.topSpacing}
          description="Enter a URL that users can visit for additional support, such as a support site, a ticket submission form, or a question submission form."
        >
          <ConfigInputField
            leadingText="https://"
            placeholder="www.example.com"
            name={SELF_SERVICE_TICKET_CREATION_URL}
            onChange={handleConfigChange}
            value={config?.SELF_SERVICE_TICKET_CREATION_URL || ""}
            attrs={{ disabled: isLoading }}
          />
        </ConfigInput>
        <ConfigInput description="Enter a title for the additional support URL">
          <ConfigInputField
            placeholder="Reach out for additional support"
            name={SELF_SERVICE_TICKET_CREATION_LINK_TEXT}
            onChange={handleConfigChange}
            value={config?.SELF_SERVICE_TICKET_CREATION_LINK_TEXT || ""}
            attrs={{ disabled: isLoading }}
          />
        </ConfigInput>

        <ConfigInput title="Proactive answers" className={styles.topSpacing}>
          <ConfigInput
            description="Enable proactive answers"
            className={classNames(styles.configInput, styles.borderedInput)}
            titleClassName={styles.switchInputTitle}
          >
            <IOSSwitch
              checked={config?.SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED || false}
              name={SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED}
              onChange={handleConfigChange}
              disabled={isLoading}
            />
          </ConfigInput>
        </ConfigInput>
        <ConfigInput
          description="Enable only for authenticated users"
          className={classNames(styles.configInput, styles.borderedInput)}
          titleClassName={styles.switchInputTitle}
        >
          <IOSSwitch
            checked={
              config?.[SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED] ||
              false
            }
            name={SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED}
            onChange={handleConfigChange}
            disabled={isLoading}
          />
        </ConfigInput>
        <ConfigInput description="Proactive Answers is enabled for all input fields matching these ids or class names">
          <ConfigInputField
            name={SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR}
            placeholder="#search"
            value={
              config?.[SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR] ||
              ""
            }
            onChange={handleConfigChange}
            attrs={{ disabled: isLoading }}
          />
        </ConfigInput>
        <ConfigInput description="Enable Proactive Answers only for these URLs. Use asterisks (*) to match on subpages (e.g. www.example.com/*).">
          {!config?.SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS?.length && (
            <WarningText
              text="You haven't specified any pages. Proactive Answers will activate
        everywhere, potentially increasing your bill."
            />
          )}
          <ConfigInputField
            leadingText="https://"
            placeholder="www.example.com"
            value={config?.SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS || ""}
            name={SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS}
            onChange={handleConfigChange}
            onAddNewItem={handleAddNewPageGlob}
            onRemoveItem={removePageGlob}
            attrs={{
              disabled: isLoading
            }}
            multiInput
          />
        </ConfigInput>
      </SectionLayout>
    </div>
  );
};
