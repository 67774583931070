import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  ArticleCreationState,
  GeneratedKnowledgeResult,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { useQuery } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { fetchArticles } from "../clients/GeneratedKnowledge/fetchArticles";
import { generateArticle } from "../clients/GeneratedKnowledge/generateArticle";

type ArticleReview = {
  isKbDraftPreparing: boolean;
  isLoading: boolean;
  showErrorMessage: boolean;
  unpublishedArticles?: GeneratedKnowledgeResult[];
};

function useArticleReview(
  ticketId: string,
  articleState: string
): ArticleReview {
  const { removeQueryParams } = useQueryParams();

  const { isLoading: isKbDraftPreparing } = useQuery(
    [WebCacheKey.TICKET_KB_ARTICLE, ticketId],
    () => generateArticle(ticketId),
    {
      enabled: !!ticketId && articleState === ArticleCreationState.GENERATION,
      retry: 3,
      staleTime: Infinity, // one hour
      onSuccess: () => {
        if (articleState === ArticleCreationState.GENERATION) {
          removeQueryParams(QUERY_PARAMS.ARTICLE_CREATION_STATE);
        }
      }
    }
  );

  const unpublishedArticlesQuery = useQuery(
    [WebCacheKey.GENERATED_KNOWLEDGE_UNPUBLISHED, ticketId],
    () => fetchArticles({ published: false, ticketId }),
    {
      enabled: !!ticketId && articleState !== ArticleCreationState.GENERATION,
      retry: 2,
      onSuccess: () => {
        if (articleState === ArticleCreationState.GENERATED) {
          removeQueryParams(QUERY_PARAMS.ARTICLE_CREATION_STATE);
        }
      }
    }
  );

  const {
    isLoading,
    isError: showErrorMessage,
    data: unpublishedArticles
  } = unpublishedArticlesQuery;

  return {
    isKbDraftPreparing,
    isLoading,
    showErrorMessage,
    unpublishedArticles
  };
}

export { useArticleReview };
