import classNames from "classnames";
import React from "react";
import { Icons } from "../Icon/Icons";
import "./Checkbox.scss";

type Props = {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
};

function Checkbox(props: Props): React.ReactElement {
  const { checked, disabled, onChange } = props;

  return (
    <div className={classNames("checkbox", { checked, disabled })}>
      <input
        type="checkbox"
        className="checkbox"
        checked={checked}
        disabled={disabled}
        onClick={(e) => e.stopPropagation()}
        onChange={() => onChange(checked)}
      />
      <Icons.Check />
    </div>
  );
}

export { Checkbox };
