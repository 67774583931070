import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppType } from "../../types/entities";
import { isSupportApp } from "../../utils/supportApp";

type SliceStateType = {
  appType: AppType;
  currentUrl: string;
};

const initialState: SliceStateType = {
  appType: isSupportApp ? AppType.SUPPORT_APP : AppType.WEB_APP,
  currentUrl: ""
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppType: (state, action: PayloadAction<AppType>) => {
      state.appType = action.payload;
    },
    updateCurrentUrl: (state, action) => {
      state.currentUrl = action.payload;
    }
  }
});

export const { setAppType, updateCurrentUrl } = appSlice.actions;

export default appSlice.reducer;
