import {
  AptAnswersConditions,
  AptAnswersFilterPurpose,
  AptAnswersFilterType
} from "@aptedge/lib-ui/src/types/entities";

type AptAnswersFilterInfo = {
  filterType: AptAnswersFilterType;
  filterPurpose: AptAnswersFilterPurpose;
  filterConditions: AptAnswersConditions[];
};

type AptAnswersFilterMap = {
  [key in AptAnswersFilterType]: AptAnswersFilterInfo;
};

const AptAnswersFilterDefinitions: AptAnswersFilterMap = {
  [AptAnswersFilterType.HIDDEN_FILTER]: {
    filterType: AptAnswersFilterType.HIDDEN_FILTER,
    filterPurpose: AptAnswersFilterPurpose.HIDE,
    filterConditions: [
      AptAnswersConditions.EQUALS,
      AptAnswersConditions.CONTAINS,
      AptAnswersConditions.STARTS_WITH,
      AptAnswersConditions.NOT_EQUALS,
      AptAnswersConditions.NOT_CONTAINS,
      AptAnswersConditions.NOT_STARTS_WITH
    ]
  },
  [AptAnswersFilterType.CSS_FILTER]: {
    filterType: AptAnswersFilterType.CSS_FILTER,
    filterPurpose: AptAnswersFilterPurpose.SHOW,
    filterConditions: [
      AptAnswersConditions.EQUALS,
      AptAnswersConditions.CONTAINS,
      AptAnswersConditions.STARTS_WITH
    ]
  }
};

export function getAptAnswersFilterInfo(
  filterType: AptAnswersFilterType
): AptAnswersFilterInfo {
  return AptAnswersFilterDefinitions[filterType];
}
