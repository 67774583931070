import { IUserListing } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchUsers(): Promise<IUserListing[]> {
  const url = `${getBackendApiUrl()}/users/`;

  return request(url);
}

export { fetchUsers };
