import { SEARCH_RESULT_TYPE } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { useTicketQuery } from "../../hooks/clients/useTicket";
import styles from "./HeaderTicketItem.module.scss";

interface HeaderTicketItemProps {
  ticketId: string;
}

const HeaderTicketItem: React.FC<HeaderTicketItemProps> = ({ ticketId }) => {
  const ticketIdInt = parseInt(ticketId);
  const ticketQuery = useTicketQuery(ticketIdInt, SEARCH_RESULT_TYPE.TICKET);
  const ticketTitle = ticketQuery.isSuccess ? ticketQuery.data.subject : null;

  return (
    <div className={styles.container}>
      <p className={styles.ticketId}>{`Ticket ${ticketId}`}</p>
      {ticketTitle && <p className={styles.ticketTitle}>{ticketTitle}</p>}
    </div>
  );
};

export { HeaderTicketItem };
