import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import React, { forwardRef, PropsWithChildren } from "react";
import { useQuery } from "react-query";
import { fetchArticle } from "../../clients/GeneratedKnowledge/fetchArticle";
import { ArticleMarkdownDisplay } from "../ArticlesReviewPage/ArticleMarkdownDisplay";
import styles from "./ViewArticleModal.module.scss";

interface ViewArticleModalProps {
  articleId: string;
  handleClose: () => void;
}

const ViewArticleModal = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ViewArticleModalProps>
>(({ articleId, handleClose }, ref) => {
  const articleQuery = useQuery(
    ["article", articleId],
    () => fetchArticle({ articleId }),
    {}
  );
  const isLoading = articleQuery.isLoading;
  const article = articleQuery?.data;

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.contentContainer}>
        {isLoading && (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )}
        {article && (
          <>
            <div className={styles.articleHeader}>
              <h3>{article.title}</h3>
              <button onClick={handleClose}>
                <Icons.Times size="lg" />
              </button>
            </div>
            <div className={styles.articleContent}>
              <ArticleMarkdownDisplay articleBody={article.body.body} />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export { ViewArticleModal };
