import {
  Loader,
  LoaderSize
} from "@aptedge/lib-ui/src/components/Loader/Loader";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import { FC } from "react";
import Helmet from "react-helmet";
import { useArticleReview } from "../../hooks/useArticleReview";
import { ErrorMessage } from "../ArticlesPage/ErrorMessage";
import { ArticleItem } from "./ArticleItem";
import styles from "./ArticlesReviewPage.module.scss";
import { EmptyState } from "./EmptyState";
import { Header } from "./Header";

const ArticlesReviewPage: FC = () => {
  const { queryParams } = useQueryParams();
  const ticketId = queryParams.get("ticketId") || "";
  const articleState = queryParams.get("articleCreationState") || "";

  const {
    isKbDraftPreparing,
    isLoading,
    showErrorMessage,
    unpublishedArticles
  } = useArticleReview(ticketId, articleState);

  const unpublishedArticlesSorted = (unpublishedArticles || []).sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  const showEmptyState =
    unpublishedArticlesSorted && unpublishedArticlesSorted.length === 0;
  const showArticleItems =
    unpublishedArticlesSorted && unpublishedArticlesSorted.length > 0;

  return (
    <>
      <Helmet>
        <title>Articles | Review Articles</title>
      </Helmet>
      <div className={styles.containerBackground}>
        <div className={styles.containerContent}>
          {isKbDraftPreparing || isLoading ? (
            <div className={styles.articlePrepareloader}>
              <Loader size={LoaderSize.LARGE} />
              <p>Preparing linked articles</p>
            </div>
          ) : (
            <>
              <div className={styles.containerHeader}>
                <Header
                  ticketId={ticketId}
                  articlesLength={unpublishedArticles?.length ?? 3}
                />
              </div>
              <div className={styles.containerArticles}>
                {isLoading && <Spinner />}
                {showErrorMessage && <ErrorMessage />}
                {showEmptyState && <EmptyState />}
                {showArticleItems &&
                  unpublishedArticlesSorted?.map((article, idx) => (
                    <ArticleItem
                      key={article.id}
                      idx={idx}
                      articlesLength={unpublishedArticles?.length ?? 0}
                      articleId={article.id}
                      ticketId={ticketId}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { ArticlesReviewPage };
