import classNames from "classnames";
import * as React from "react";
import styles from "./TicketsTableHeader.module.scss";

const TicketsTableHeader: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerItem}>Ticket</div>
      <div className={styles.headerItem}>Article count</div>
      <div className={styles.headerItem}>Created date</div>
      <div className={styles.headerItem}>Created by</div>
      <div className={classNames(styles.headerItem, styles.actionsItem)}>
        Actions
      </div>
    </div>
  );
};

export { TicketsTableHeader };
