import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { UserSupport } from "@aptedge/lib-ui/src/types/entities";
import { WHOAMI_QUERY_OPTIONS } from "@aptedge/lib-ui/src/utils/query";
import { useQuery, UseQueryResult } from "react-query";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

export type WhoAmIResponse = UseQueryResult<UserSupport>;

function getWhoAmI(): Promise<UserSupport> {
  const url = `${getBackendApiUrl()}/whoami/`;

  return request(url);
}

export const useWhoami = (): WhoAmIResponse => {
  // unlike the support app, no need to include the user email in the cache key -- we clear the
  // cache on logout or login, and we don't know the email at this point anyway (unlike in the
  // support app, where we have the email from the SDK)
  return useQuery([CACHE_KEY.WHOAMI], getWhoAmI, WHOAMI_QUERY_OPTIONS);
};
