import { useArrowDown } from "@aptedge/lib-ui/src/hooks/useArrowDown";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswerCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { updateSelectedSearchResult } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { Keys } from "@aptedge/lib-ui/src/styles/keys";
import {
  ICompositeResult,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { HTMLProps, RefObject, useEffect, useRef } from "react";
import { useRecentSearch } from "../../useRecentSearches/useRecentSearches";
import { triggerWebInteractionEventBySourceType } from "../utils/events";

export interface IUseArrowNavigationSL {
  searchContainerRef: RefObject<HTMLDivElement>;
  listBoxRef: RefObject<HTMLDivElement>;
  highlighted: number;
  getListBoxProps: () => HTMLProps<HTMLDivElement>;
  getListItemProps: (
    idx: number,
    className?: string | undefined
  ) => HTMLProps<HTMLDivElement>;
  determineHandler: (result: ICompositeResult, idx: number) => void;
  recentSearches: ICompositeResult[] | null;
}
const useArrowNavigationSL = (page: number): IUseArrowNavigationSL => {
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const listBoxRef = useRef<HTMLDivElement>(null);
  const {
    searchQuery,
    searchResults,
    totalSearchResults,
    searchFilterSubType,
    searchFilter,
    isSearchFocused
  } = useAppSelector((state) => state.search);
  const [recentSearches, addRecentSearch] = useRecentSearch();
  const { queryParams, updateParams } = useQueryParams();
  const dispatch = useAppDispatch();

  const list =
    !!searchQuery && !!searchResults ? searchResults : recentSearches || [];

  const determineHandler = (result: ICompositeResult, idx: number): void => {
    addRecentSearch(result);
    dispatch(updateSelectedSearchResult(result));
    dispatch(updateAnswerCardVisibility(false));
    updateParams(QUERY_PARAMS.RESULT_ID, result.id.toString());
    triggerWebInteractionEventBySourceType(
      result,
      searchQuery,
      searchResults,
      totalSearchResults,
      idx,
      page,
      searchFilter,
      searchFilterSubType
    );
  };
  /**
   * useArrowKey hook highlight the 1st value from the list by default.
   * in array null data is added so that unless user clicks arrow key down no data is selected
   * in order to get the right data use [idx-1] from list
   */

  const handleKeyDown = (idx: number, key: Keys): void => {
    if (key === Keys.ENTER && !isSearchFocused && !!list[idx - 1]) {
      if (list === searchResults) {
        determineHandler(searchResults[idx - 1], idx);
      }
    }
  };

  const handleMouseClick = (idx: number): void => {
    let result;
    if (list === searchResults) {
      result = searchResults[idx - 1];
    } else {
      setHighlighted(0);
      result = list[idx - 1];
    }
    determineHandler(result, idx);
  };

  const {
    highlighted,
    setHighlighted,
    getListBoxProps,
    getListItemProps
  } = useArrowDown({
    ref: searchContainerRef,
    role: "search-result",
    listBoxRef,
    items: [null, ...list],
    onKeyDown: handleKeyDown,
    onMouseClick: handleMouseClick
  });
  // Reset highlighted to first item when items change.
  useEffect(() => {
    setHighlighted(0);
  }, [setHighlighted, searchQuery]);
  useEffect(() => {
    if (searchQuery) return;
    const sourceId = queryParams.get(QUERY_PARAMS.RESULT_ID);
    const selectedItem = recentSearches?.find(
      (result) => result.id.toString() === sourceId
    );
    dispatch(updateSelectedSearchResult(selectedItem as ICompositeResult));
  }, [dispatch, searchQuery, queryParams, recentSearches]);

  return {
    searchContainerRef,
    listBoxRef,
    highlighted,
    getListBoxProps,
    getListItemProps,
    recentSearches,
    determineHandler
  };
};

export default useArrowNavigationSL;
