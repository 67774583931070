import { isLocal } from "../config";
import { GTM_EVENTS, dataLayerPush } from "../event";

export const isSupportApp =
  !window.location.host.includes("aptedge.io") &&
  !window.location.host.includes("localhost:3000");

export const urlProtocol = isLocal() ? "http" : "https";

const triggerViewSearchInWebAppEvent = (
  ticketSubject: string,
  ticketId: string
): void => {
  dataLayerPush({
    event: GTM_EVENTS.GTM_SUPPORT_CLICK_VIEW_SEARCH_IN_WEB_APP,
    data: {
      ticket_title: ticketSubject,
      ticket_id: ticketId
    }
  });
};

export const openInWebApp = (
  aptEdgeBaseUrl: string,
  queryString: string,
  ticketSubject: string,
  ticketId: string
): void => {
  const url = `${urlProtocol}://${aptEdgeBaseUrl}/search?${queryString}`;
  triggerViewSearchInWebAppEvent(ticketSubject, ticketId);
  window.open(url, "_blank");
};

export const openGeneratedKnowledgeArticleInWebApp = (
  aptEdgeBaseUrl: string,
  articleId: string
): void => {
  const url = `${urlProtocol}://${aptEdgeBaseUrl}/articles?articleId=${articleId}`;
  window.open(url, "_blank");
};

export const openGeneratedKnowledgeReviewInWebApp = (
  aptEdgeBaseUrl: string,
  ticketId: number,
  articleCreationState: string
): void => {
  const url = `${urlProtocol}://${aptEdgeBaseUrl}/articles/review?ticketId=${ticketId}&articleCreationState=${articleCreationState}`;
  window.open(url, "_blank");
};
