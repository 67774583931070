import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IUserListData } from "@aptedge/lib-ui/src/types/entities";
import { faCaretDown, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import capitalize from "lodash/capitalize";
import React from "react";
import { useAuth } from "../../../context/AuthContext";
import "./AdminDashboard.scss";

interface IProps {
  user: IUserListData;
  className?: string;
  onOptionSelect: () => void;
  handleDelete: () => void;
  index: number;
}
const UserListItem: React.FC<IProps> = ({
  user,
  onOptionSelect,
  handleDelete,
  index
}) => {
  const { user: loggedUser } = useAuth();
  const text = user?.email ? capitalize(user?.email?.charAt(0)) : "N / A";
  const avatarColorTeal = index % 2;
  return (
    <div className="user-list">
      <div className="user-assignee">
        <Avatar
          color={avatarColorTeal ? "var(--primary-hover)" : "var(--danger)"}
          text={text}
          size="40px"
        />
      </div>
      <Tooltip content={<span>{user.email}</span>} placement="top">
        <div className="user-email">{user.email}</div>
      </Tooltip>
      <div
        className="user-status"
        onClick={(e) => e.stopPropagation()}
        role="button"
      >
        <Dropdown.Container
          disabled={loggedUser?.email === user.email}
          className="options"
        >
          <Dropdown.Toggle>
            {" "}
            {user.admin ? "Admin" : "User "} <Icon icon={faCaretDown} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={onOptionSelect}>
              {user.admin ? "User" : "Admin"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown.Container>
      </div>
      <button
        disabled={loggedUser?.email === user.email}
        className="delete"
        onClick={handleDelete}
      >
        <Icon icon={faMinusCircle} />
      </button>
    </div>
  );
};

export { UserListItem };
