import { GeneratedKnowledgeResult } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./ArticlesTable.module.scss";
import { ArticlesTableHeader } from "./ArticlesTableHeader";
import { ArticlesTableItem } from "./ArticlesTableItem";

interface ArticlesTableProps {
  articles: GeneratedKnowledgeResult[];
}

const ArticlesTable: React.FC<ArticlesTableProps> = ({ articles }) => {
  return (
    <div className={styles.container}>
      <ArticlesTableHeader />
      {articles.map((article, idx) => (
        <ArticlesTableItem key={article.id} article={article} idx={idx} />
      ))}
    </div>
  );
};

export { ArticlesTable };
