import * as React from "react";
import styles from "./ArticlesTableHeader.module.scss";

const ArticlesTableHeader: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerItem}>Article</div>
      <div className={styles.headerItem}>Source Ticket</div>
      <div className={styles.headerItem}>Created date</div>
      <div className={styles.headerItem}>Created by</div>
      <div className={styles.headerItem}>Actions</div>
    </div>
  );
};

export { ArticlesTableHeader };
