import * as React from "react";
import styles from "./Header.module.scss";
import { HeaderSummary } from "./HeaderSummary";
import { HeaderTicketItem } from "./HeaderTicketItem";

interface HeaderProps {
  ticketId: string;
  articlesLength: number;
}

const Header: React.FC<HeaderProps> = ({ ticketId, articlesLength }) => {
  return (
    <div className={styles.container}>
      <HeaderSummary articlesLength={articlesLength} />
      <HeaderTicketItem ticketId={ticketId} />
    </div>
  );
};

export { Header };
