import * as React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchArticles } from "../../clients/GeneratedKnowledge/fetchArticles";
import { TabItem } from "./TabItem";
import styles from "./Tabs.module.scss";

type TicketId = string | number;

interface TabsProps {
  currentTab: "savedArticles" | "pendingReview";
}

const Tabs: React.FC<TabsProps> = ({ currentTab }) => {
  const unpublishedArticlesQuery = useQuery(
    [WebCacheKey.GENERATED_KNOWLEDGE_UNPUBLISHED],
    () => fetchArticles({ published: false }),
    {}
  );
  const tickets: TicketId[] = [];
  const unpublishedArticles = unpublishedArticlesQuery?.data;
  unpublishedArticles?.forEach((article) => {
    const { ticket_id: ticketId } = article.ticketIds[0];
    const ticket = tickets.find((ticket) => ticket === ticketId);
    if (!ticket) {
      tickets.push(ticketId);
    }
  });
  const unpublishedTicketsCount = tickets.length;
  return (
    <div className={styles.container}>
      <TabItem
        to="/articles"
        label="Saved Articles"
        count={null}
        isCurrent={currentTab === "savedArticles"}
      />
      <TabItem
        to="/articles/pending"
        label="Pending Review"
        count={unpublishedTicketsCount}
        isCurrent={currentTab === "pendingReview"}
      />
    </div>
  );
};

export { Tabs };
