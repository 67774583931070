import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { resetSearch } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { Routes } from "../../../routes/routes";
import "./SettingsNav.scss";

const SettingsNav: React.FC = () => {
  const { user } = useAuth();
  const { flags } = useFlags();
  const adminsOnly = user?.admin || false;
  const onboardedOnly = user?.onboardingComplete || false;
  const dispatch = useAppDispatch();
  const onRouteChange = (): void => {
    dispatch(resetSearch());
  };
  return (
    <nav className="nav navbar settings sticky-top" onClick={onRouteChange}>
      <div className="container-fluid">
        <nav className="nav navbar">
          {adminsOnly && (
            <NavLink className="nav-item mr-4" exact to={Routes.FILTERS_CONFIG}>
              Filters
            </NavLink>
          )}
          {(adminsOnly || flags.demo) && (
            <NavLink className="nav-item mr-4" exact to={Routes.SETTINGS}>
              Integrations
            </NavLink>
          )}
          <NavLink
            className="nav-item mr-4"
            exact
            to={Routes.SETTINGS_MACHINE_LEARNING}
          >
            Machine Learning
          </NavLink>
          <NavLink
            className="nav-item mr-4"
            exact
            to={Routes.SETTINGS_WORKFLOW}
          >
            Workflow
          </NavLink>
          {(onboardedOnly || flags.demo) && (
            <NavLink
              className="nav-item mr-4"
              exact
              to={Routes.SETTINGS_STATUS}
            >
              Status
            </NavLink>
          )}
          {flags.cssAnalytics && (
            <NavLink className="nav-item mr-4" exact to={Routes.CSS_ANALYTICS}>
              Analytics
            </NavLink>
          )}
          {(adminsOnly || flags.demo) && (
            <NavLink className="nav-item" exact to={Routes.SETTINGS_APPS}>
              Apps
            </NavLink>
          )}
          {adminsOnly && (
            <NavLink
              className="ml-4 nav-item"
              exact
              to={Routes.ADMIN_DASHBOARD}
            >
              Admin Dashboard
            </NavLink>
          )}
          {adminsOnly && flags.cssConfigPage && (
            <NavLink className="ml-4 nav-item" exact to={Routes.CSS_CONFIG}>
              Self Service App
            </NavLink>
          )}
        </nav>
      </div>
    </nav>
  );
};

export { SettingsNav };
