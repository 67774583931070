import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import useModal from "@aptedge/lib-ui/src/hooks/useModal/useModal";
import { ArticleStatus } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { deleteArticle } from "../../clients/GeneratedKnowledge/deleteArticle";
import { editArticle } from "../../clients/GeneratedKnowledge/editArticle";
import { fetchArticle } from "../../clients/GeneratedKnowledge/fetchArticle";
import { EditArticleModal } from "../ArticlesPage/EditArticleModal";
import styles from "./ArticleItem.module.scss";
import { ArticleMarkdownDisplay } from "./ArticleMarkdownDisplay";

interface ArticleItemProps {
  idx: number;
  articlesLength: number;
  articleId: string;
  ticketId: string | number;
}

interface EditArticleArgs {
  title: string;
  body: string;
}

const triggerEvent = (
  eventName: string,
  ticketId: string | number,
  articleId: string,
  articleTitle: string,
  articleBody: string
): void => {
  dataLayerPush({
    event: eventName,
    data: {
      article_id: articleId,
      ticket_id: ticketId,
      article_title: articleTitle,
      article_body: articleBody,
      article_status: ArticleStatus.DRAFT
    }
  });
};

const ArticleItem: React.FC<ArticleItemProps> = ({
  idx,
  articlesLength,
  articleId,
  ticketId
}) => {
  const { modalVisibility, closeModal, modalRef } = useModal();
  const queryClient = useQueryClient();
  const articleQuery = useQuery(
    [WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE, articleId],
    () => fetchArticle({ articleId }),
    {}
  );
  const isLoading = articleQuery.isLoading;
  const article = articleQuery?.data;
  const articleContent = `# ${article?.title}\n\n${article?.body.body}`;

  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_UNPUBLISHED,
        ticketId
      ]);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE,
        articleId
      ]);
    }
  });

  const editArticleMutation = useMutation(editArticle, {
    onSuccess: () => {
      closeModal(false);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_UNPUBLISHED,
        ticketId
      ]);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE,
        articleId
      ]);
    }
  });

  const handleEditArticleOpen = (): void => {
    closeModal(true);
  };

  const handleCloseModal = (): void => {
    closeModal(false);
  };

  const handleEditArticle = ({ title, body }: EditArticleArgs): void => {
    editArticleMutation.mutate({
      articleId,
      title,
      body,
      published: false
    });
  };

  const handleSaveArticle = (): void => {
    if (article) {
      editArticleMutation.mutate({
        articleId,
        title: article?.title,
        body: article?.body.body,
        published: true
      });
      triggerEvent(
        GTM_EVENTS.GTM_SAVE_KB_GEN_ARTICLE,
        ticketId,
        articleId,
        article.title,
        article.body.body
      );
    }
  };

  const handleDeleteArticle = (): void => {
    deleteArticleMutation.mutate({ articleId: articleId });
    triggerEvent(
      GTM_EVENTS.GTM_DISCARD_KB_GEN_ARTICLE,
      ticketId,
      articleId,
      article?.title || "",
      article?.body.body || ""
    );
  };

  return (
    <div className={styles.container}>
      <p className={styles.articleCount}>{`Article ${
        idx + 1
      } of ${articlesLength}`}</p>
      <div className={styles.articleCard}>
        <div className={styles.articleContent}>
          {isLoading && (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          )}
          {article && <ArticleMarkdownDisplay articleBody={articleContent} />}
        </div>
        <div className={styles.articleActions}>
          <button onClick={handleEditArticleOpen} className={styles.editButton}>
            <Icons.Edit />
            Edit
          </button>
          <button
            onClick={handleDeleteArticle}
            className={styles.discardButton}
          >
            <Icons.Trash />
            Discard
          </button>
          <button
            onClick={handleSaveArticle}
            className={styles.saveArticleButton}
          >
            <Icons.Save />
            Save Article
          </button>
        </div>
      </div>
      {modalVisibility && article && (
        <EditArticleModal
          ref={modalRef}
          articleId={article.id}
          handleClose={handleCloseModal}
          handleSave={handleEditArticle}
        />
      )}
    </div>
  );
};

export { ArticleItem };
