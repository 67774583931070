import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import * as React from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchArticles } from "../../clients/GeneratedKnowledge/fetchArticles";
import styles from "./ArticlesPage.module.scss";
import { ArticlesTable } from "./ArticlesTable";
import { EmptyState } from "./EmptyState";
import { ErrorMessage } from "./ErrorMessage";
import { Header } from "./Header";
import { Tabs } from "./Tabs";

const ArticlesPage: React.FC = () => {
  const articlesQuery = useQuery(
    [WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED],
    () => fetchArticles({ published: true }),
    {
      retry: 2
    }
  );
  const isLoading = articlesQuery.isLoading;
  const showErrorMessage = articlesQuery.isError;
  const articles = articlesQuery?.data;
  const showEmptyState = articles && articles?.length === 0;
  const showTicketsTable = articles && articles?.length > 0;

  return (
    <>
      <Helmet>
        <title>Articles | Saved Articles</title>
      </Helmet>
      <div className={styles.container}>
        <Header />
        <Tabs currentTab="savedArticles" />
        {isLoading && <Spinner />}
        {showErrorMessage && <ErrorMessage />}
        {showEmptyState && <EmptyState />}
        {showTicketsTable && (
          <div className={styles.articlesTableContainer}>
            <ArticlesTable articles={articles || []} />
          </div>
        )}
      </div>
    </>
  );
};

export { ArticlesPage };
